<template>
  <transition name="toBottom">
    <header class="menu-main">
      <!-- <transition name="toBottom">
        <div
          v-if="showVseMaykiBanner"
          class="vsemayki-banner"
        >
          <button @click="closeBannerVseMayki"><i /></button>
          <img src="https://bimg.vsemayki.ru/369aa5ff-52d3-4d16-83fa-00e86da89c60.jpg" alt="Sale - Anime777 shop">
          <a
            href="https://Наш магазин"
            target="_blank"
            rel="norefferer nofollow noopener"
          />
        </div>
      </transition> -->

      <!-- <i
        v-if="isDesktop && showSnow"
        @click="showSnow = !showSnow"
        class="snow-switch" title="Выключить снег"
      />
      <snowComponent v-if="showSnow" /> -->

      <nav v-if="isDesktop" class="menu-desktop">
        <div class="header-menu">
          <div class="logotype">
            <i @click="usProjects = !usProjects" class="arrow" :class="{ active: usProjects }" />
            <div v-if="usProjects" class="us-projects">
              <i @click="closeUsProjects" class="close"/>
              <ul style="margin-top:10px;">
                <li>
                  <a href="https://taxivkino.ru" rel="noopener noreferrer" style="padding:0;" target="_blank">
                    Taxi v Kino (Такси в Кино) &rarr;
                  </a>
                  <span style="opacity:0.75;">фильмы и сериалы онлайн</span>
                </li>
                <li style="margin-top:10px;">
                  <a href="https://moredoram.ru" rel="noopener noreferrer" style="padding:0;" target="_blank">
                    More Doram (Море Дорам) &rarr;
                  </a>
                  <span style="opacity:0.75;">дорамы онлайн</span>
                </li>
              </ul>
            </div>
            <nuxt-link to="/">
              <span itemprop="name">
                Anime777
              </span>
              <span class="tagline">
                Смотри и читай
              </span>
            </nuxt-link>
            <!-- <i class="christmasstree" /> -->
            <i class="butterfly" />
          </div>

          <div class="menu-search">
            <ul
              v-if="!showSearchInput"
              :class="{'main-menu': true, mbottom: true, active: showMenu}"
            >
              <li @mouseenter="showMenu = false">
                <nuxt-link data-route="schedule-anime" to="/schedule-anime">
                  <strong>Календарь <sup class="flame"><img src="/static_files/icons/hot.svg" alt=""></sup></strong> <sub>онгоингов</sub>
                </nuxt-link>
              </li>
              <li @mouseenter="showMenu = false">
                <nuxt-link data-route="updates" to="/updates/">
                  <strong>Обновления</strong> <sub>каталогов</sub>
                </nuxt-link>
              </li>
              <li :class="{ active: showMenu.comics }" @mouseenter="showMenus('comics')" @mouseleave="hideMenus('comics')">
                <nuxt-link data-route="comics" to="/comics">
                  <strong>Манга</strong> <sub>комиксы</sub>
                </nuxt-link>
              </li>
              <li :class="{ active: showMenu.anime }" @mouseenter="showMenus('anime')" @mouseleave="hideMenus('anime')">
                <nuxt-link data-route="watch" to="/watch">
                  <strong>Аниме</strong> <sub>онлайн</sub>
                </nuxt-link>
              </li>
              <li :class="{ active: showMenu.ranobes }" @mouseenter="showMenus('ranobes')" @mouseleave="hideMenus('ranobes')">
                <nuxt-link data-route="ranobes" to="/ranobes">
                  <strong>Ранобэ</strong> <sub>новеллы</sub>
                </nuxt-link>
              </li>
              <li @mouseenter="showMenu = false">
                <nuxt-link data-route="cinema" to="/cinema/">
                  <strong>Совместный <sup class="flame"><img src="/static_files/icons/hot.svg" alt=""></sup></strong> <sub>просмотр</sub>
                </nuxt-link>
              </li>
              <li @mouseenter="showMenu = false">
                <nuxt-link data-route="random" to="/random">
                  <strong>Случайное</strong> <sub>аниме</sub>
                </nuxt-link>
              </li>
            </ul>
            <div v-else class="ssearch">
              <i :class="{ focused }" class="icon-search" />
              <select
                v-model="searchType"
                :class="{ focused }"
                class="ssearch-type"
              >
                <option value="db">
                  Аниме
                </option>
                <option value="anime_characters">
                  Персонаж
                </option>
                <option value="comics">
                  Комикс
                </option>
                <option value="ranobes">
                  Ранобэ
                </option>
                <option value="games">
                  Игра
                </option>
              </select>
              <searchhints-component :type="searchType" />
            </div>
            <i
              v-if="!showSearchInput"
              @click="showSearchInput = !showSearchInput"
              class="ms-icons search-open"
            />
            <i
              v-else
              @click="showSearchInput = !showSearchInput"
              class="ms-icons search-close"
            />
          </div>
          <!-- <i @click="switchLocale" title="Отображать англ названия" class="icon-locale" /> -->
          <div v-if="user" class="profile">
            <i @click="switchTheme" title="Сменить тему" class="icon-moon" />
            <div
              @click="showProfileMenu = !showProfileMenu"
              :class="`group-${user.group} ${user.aura_color ? `aura-color-${user.aura_color}` : ''} ${user.gender ? `gender-${user.gender}` : ''}`"
              class="short-profile-avatar"
            >
              <!-- <span v-if="user.group === 777">Premium</span> -->
              <img
                :src="user.photo || '/no-avatar.svg'"
                alt=""
              >
              <sup v-if="user.notifications" class="notifi-count">{{ user.notifications > 99 ? `99+` : user.notifications }}</sup>
            </div>
            <transition name="toBottom">
              <ul v-if="showProfileMenu">
                <span :class="{ 'active': showProfileMenu}" @click="showProfileMenu = !showProfileMenu">
                  <!-- {{ user.email }} -->
                  <i class="icon-close" />
                </span>
                <nuxt-link to="/donate" tag="div" class="sitecoin balance">
                  <i />
                  <span>{{ user.balance.toFixed(2) }} Монет</span>
                  <span style="display:block;top:-44px;left:-27px;background-color: transparent;font-size:1.2rem;color:blue;">Пополнить</span>
                </nuxt-link>
                <nuxt-link to="/donate" tag="li" v-if="user.group === 777" class="user-premium">
                  <strong v-if="user.premium_date">Premium до {{ user.premium_date | filterDate }}</strong>
                  <strong v-else>Premium до Бесконечности</strong>
                </nuxt-link>
                <li class="profile-avatar">
                  <div style="position: relative;">
                    <aura-component :user="user" />
                    <artifact-component :user="user" />
                    <lvl-component v-if="user.exp" :exp="user.exp" />
                    <div :class="`${user.aura_color ? `aura-color-${user.aura_color}` : ''} ${user.gender ? `gender-${user.gender}` : ''}`" class="avatar">
                      <img
                        :src="user.photo || '/no-avatar.svg'"
                        alt=""
                      >
                    </div>
                  </div>
                  <!-- <span @click="avatarUploadTrigger">Изменить</span>
                  <input
                    ref="avatarUpload"
                    type="file"
                    style="display:none;"
                    accept="image/gif, image/png, image/jpeg, image/jpg"
                    @change="avatarUpload"
                  > -->
                </li>
                <li class="user-name">
                  <strong>{{ user.name ? user.name : 'No name' }}</strong>
                </li>
                <nuxt-link v-if="!showSettging" to="/profile" tag="li" class="open-profile">
                  Перейти в профиль
                </nuxt-link>
                <li class="col-2">
                  <span>
                    <nuxt-link to="/notifications">&#128276; Уведомления <sup v-if="user.notifications">{{ user.notifications > 99 ? `99+` : user.notifications }}</sup></nuxt-link>
                  </span>
                  <span><nuxt-link to="/cinema/?my=true">&#128250; Мои комнаты</nuxt-link></span>
                </li>
                <li class="col-2">
                  <span><nuxt-link to="/profile?tab=favs">&#129505; Закладки</nuxt-link></span>
                  <span><nuxt-link to="/profile?tab=pms">&#128233; Письма</nuxt-link></span>
                </li>
                <li class="col-2">
                  <span>
                    <nuxt-link to="/store">&#128302; Магазин</nuxt-link>
                  </span>
                  <span @click="$store.commit('toggleFeedback', true)">🎧 Поддержка</span>
                </li>
                <li class="col-2">
                  <span>
                    <nuxt-link to="/partners">&#127774; Партнерка</nuxt-link>
                  </span>
                  <span>
                    <nuxt-link to="/promocodes">&#11088; Промокоды</nuxt-link>
                  </span>
                </li>
                <li v-if="!showSettging" @click="logout" style="cursor:pointer;">
                  <span>&#9940; Выйти из аккаунта</span>
                </li>
              </ul>
            </transition>
          </div>
          <div v-else class="profile">
            <i @click="switchTheme" title="Сменить тему" class="icon-moon" />
            <button :class="{ active: showAuthForm }" @click="showAuthForm = !showAuthForm">
              Войти
            </button>
            <transition name="toBottom">
              <div v-if="showAuthForm" class="auth-form">
                <button :class="{ active: showAuthForm }" @click="showAuthForm = !showAuthForm">
                  Вход на сайт
                  <i class="icon-close" />
                </button>
                <div class="auth-social">
                  <span @click="authSocial('facebook')" class="facebook"><i /> Facebook</span>
                  <span @click="authSocial('google')" class="google"><i /> Google</span>
                  <span @click="authSocial('vkontakte')" class="vkontakte"><i /> ВКонтакте</span>
                  <span @click="authSocial('odnoklassniki')" class="odnoklassniki"><i /> Одноклассники</span>
                  <span @click="authSocial('mailru')" class="mailru"><i /> Mail.ru</span>
                  <span @click="authSocial('yandex')" class="yandex"><i /> Яндекс</span>
                </div>
              </div>
            </transition>
          </div>
        </div>

        <div v-show="showMenu.anime" class="sub-menus" @mouseleave="showMenu.anime = false">
          <div class="wrapper">
            <div
              v-show="showMenu.anime"
              onselectstart="return false"
              onmousedown="return false"
              class="sub-menu"
              @mouseenter="showMenu.anime = true"
              @mouseleave="showMenu.anime = false"
            >
              <h2>
                Самые популярные аниме
              </h2>
              <div v-if="enterData.top_anime.length" class="top-videos">
                <nuxt-link
                  v-for="film in enterData.top_anime"
                  :key="`monthtop-menu-anime-${film.id}`"
                  :title="film.title_ru"
                  :to="'/watch/' + postUrl(film.id, film.title_ru)"
                  class="top-item"
                >
                  <div class="img-wrap">
                    <img v-if="showMenu.anime" :src="filmPoster(film)" :alt="'Постер ' + film.title_ru">
                  </div>
                  <strong>{{ film.title_ru.length > 50 ? `${film.title_ru.slice(0, 47)}...` : film.title_ru }}</strong>
                </nuxt-link>
              </div>
              <template v-if="showMenu.anime">
                <div class="h3">
                  <nuxt-link to="/watch" style="color:lightgreen;">
                    Каталог аниме
                    <img src="/static_files/icons/right-chevron-dark.svg" style="width: 10px;height:10px;" alt="">
                  </nuxt-link>
                </div>
                <div class="h3">
                  Аниме по жанрам
                </div>
                <nuxt-link v-for="g in animeGenres" :key="`main-menu-genre-${g}`" :to="'/watch?genre=' + g" tag="span">
                  {{ g }}
                </nuxt-link>
                <div class="h3">
                  Аниме по странам
                </div>
                <nuxt-link v-for="c in animeCountries" :key="`main-menu-country-${c}`" :to="'/watch?country=' + c" tag="span">
                  {{ c }}
                </nuxt-link>
                <div class="h3">
                  Аниме по годам
                </div>
                <nuxt-link v-for="y in years.slice(0, 5)" :key="`main-menu-years-${y}`" :to="'/watch?year=' + y" tag="span">
                  {{ y }}
                </nuxt-link>
              </template>
            </div>
          </div>
        </div>

        <div v-show="showMenu.comics" class="sub-menus" @mouseleave="showMenu.comics = false">
          <div class="wrapper">
            <div
              v-show="showMenu.comics"
              onselectstart="return false"
              onmousedown="return false"
              class="sub-menu"
              @mouseenter="showMenu.comics = true"
              @mouseleave="showMenu.comics = false"
            >
              <h2>
                Самые популярные комиксы
              </h2>
              <div v-if="enterData.top_comics.length" class="top-videos">
                <nuxt-link
                  v-for="film in enterData.top_comics"
                  :key="`monthtop-menu-comics-${film.id}`"
                  :title="film.title_ru"
                  :to="'/comics/' + postUrl(film.id, film.title_ru)"
                  class="top-item"
                >
                  <div class="img-wrap">
                    <img v-if="showMenu.comics" :src="film.poster" :alt="'Постер ' + film.title_ru">
                  </div>
                  <strong>
                    {{
                      film.title_ru.indexOf('/') >= 0
                        ? film.title_ru.slice(film.title_ru.indexOf('/') + 1)
                        : film.title_ru
                    }}
                  </strong>
                </nuxt-link>
              </div>
              <template v-if="showMenu.comics">
                <div class="h3">
                  <nuxt-link to="/comics" style="color:lightgreen;">
                    Каталог комиков
                    <img src="/static_files/icons/right-chevron-dark.svg" style="width: 10px;height:10px;" alt="">
                  </nuxt-link>
                </div>
                <div class="h3">
                  Комиксы по жанрам
                </div>
                <nuxt-link v-for="g in comicsGenres" :key="`main-menu-comics-genre-${g}`" :to="'/comics?genre=' + g" tag="span">
                  {{ g }}
                </nuxt-link>
                <div class="h3">
                  Комиксы по годам
                </div>
                <nuxt-link v-for="y in years.slice(0, 10)" :key="`main-menu-comics-years-${y}`" :to="'/comics?year=' + y" tag="span">
                  {{ y }}
                </nuxt-link>
              </template>
            </div>
          </div>
        </div>

        <div v-show="showMenu.ranobes" class="sub-menus" @mouseleave="showMenu.ranobes = false">
          <div class="wrapper">
            <div
              v-show="showMenu.ranobes"
              onselectstart="return false"
              onmousedown="return false"
              class="sub-menu"
              @mouseenter="showMenu.ranobes = true"
              @mouseleave="showMenu.ranobes = false"
            >
              <h2>
                Самые популярные ранобэ
              </h2>
              <div v-if="enterData.top_ranobes.length" class="top-videos">
                <nuxt-link
                  v-for="film in enterData.top_ranobes"
                  :key="`monthtop-menu-ranobes-${film.id}`"
                  :title="film.title_ru"
                  :to="'/ranobes/' + postUrl(film.id, film.title_ru)"
                  class="top-item"
                >
                  <div class="img-wrap">
                    <img v-if="showMenu.ranobes" :src="film.poster" :alt="'Постер ' + film.title_ru">
                  </div>
                  <strong>{{ film.title_ru.length > 50 ? `${film.title_ru.slice(0, 47)}...` : film.title_ru }}</strong>
                </nuxt-link>
              </div>
              <template v-if="showMenu.ranobes">
                <div class="h3">
                  <nuxt-link to="/ranobes" style="color:lightgreen;">
                    Каталог ранобэ
                    <img src="/static_files/icons/right-chevron-dark.svg" style="width: 10px;height:10px;" alt="">
                  </nuxt-link>
                </div>
                <div class="h3">
                  Ранобэ по жанрам
                </div>
                <nuxt-link v-for="g in ranobeGenres" :key="`main-menu-ranobe-genre-${g}`" :to="'/ranobes?genre=' + g" tag="span">
                  {{ g }}
                </nuxt-link>
                <div class="h3">
                  Ранобэ по странам
                </div>
                <nuxt-link v-for="c in ranobeCountries" :key="`main-menu-ranobe-country-${c}`" :to="'/ranobes?country=' + c" tag="span">
                  {{ c }}
                </nuxt-link>
                <div class="h3">
                  Ранобэ по годам
                </div>
                <nuxt-link v-for="y in years.slice(0, 10)" :key="`main-menu-ranobe-years-${y}`" :to="'/ranobes?year=' + y" tag="span">
                  {{ y }}
                </nuxt-link>
              </template>
            </div>
          </div>
        </div>

        <div class="other-cats">
          <strong>Тоже интересно:</strong>
          <!-- <nuxt-link data-route="fundoms" to="/fundoms">Фандомы <sup class="flame"><img src="/static_files/icons/hot.svg" alt=""></sup></nuxt-link> -->
          <nuxt-link data-route="download-anime" to="/download-anime">Скачать аниме</nuxt-link>
          <nuxt-link data-route="popular" to="/popular">Топ100 - ваш выбор <sup class="flame"><img src="/static_files/icons/hot.svg" alt=""></sup></nuxt-link>
          <nuxt-link data-route="tv" to="/tv">Стримы онлайн</nuxt-link>
          <nuxt-link data-route="avatars" to="/avatars">Аватарки</nuxt-link>
          <nuxt-link data-route="users" to="/users">Поиск друзей</nuxt-link>
          <nuxt-link data-route="anime-new" to="/anime-new">Новинки аниме <sup class="flame"><img src="/static_files/icons/hot.svg" alt=""></sup></nuxt-link>
          <strong @click="showCatsSpoiler = !showCatsSpoiler" class="spoiler">
            Ещё <i :class="{ active: showCatsSpoiler }" />
          </strong>
          <div v-show="showCatsSpoiler">
            <nuxt-link data-route="battles" to="/apps">Мини-игры</nuxt-link>
            <!-- <nuxt-link data-route="battles" to="/apps/battles">Битва дня</nuxt-link> -->
            <nuxt-link data-route="characters" to="/characters">Имена персонажей</nuxt-link>
            <nuxt-link data-route="blogs" to="/blogs">Блоги</nuxt-link>
            <nuxt-link data-route="games" to="/games/">Игры</nuxt-link>
            <nuxt-link data-route="memes" to="/memes">Мемы</nuxt-link>
            <nuxt-link data-route="amv" to="/amv">AMV клипы</nuxt-link>
            <nuxt-link data-route="wallpapers" to="/wallpapers">Обои</nuxt-link>
            <nuxt-link data-route="gifs" to="/gifs">Гифки</nuxt-link>
            <nuxt-link data-route="chat" to="/chat">Чат-рулетка</nuxt-link>
          </div>
        </div>
      </nav>
      <nav v-else class="menu-mobile">
        <div class="header-menu">
          <div class="left">
            <i @click="toggleMenu = !toggleMenu" :class="{ 'menu-mobile-toggle': true, 'close': toggleMenu }" />
            <i @click="switchTheme" class="icon-moon" />
          </div>
          <div class="logotype">
            <nuxt-link to="/">
              <span>
                Anime777
              </span>
              <span>
                Смотри и читай
                <!-- <img src="/logo.svg" alt="Anime 777"> -->
              </span>
            </nuxt-link>
            <!-- <i class="christmasstree" /> -->
            <i class="butterfly" />
          </div>
          <div class="right">
            <div v-if="user" class="profile">
              <i class="icon-short-search" @click="showSearch" />
              <div
                @click="showProfileMenu = !showProfileMenu"
                :class="`group-${user.group} ${user.aura_color ? `aura-color-${user.aura_color}` : ''}  ${user.gender ? `gender-${user.gender}` : ''}`"
                class="short-profile-avatar mobile"
              >
                <!-- <span v-if="user.group === 777">Premium</span> -->
                <img
                  :src="user.photo || '/no-avatar.svg'"
                  alt=""
                >
                <sup v-if="user.notifications" class="notifi-count">
                  {{ user.notifications > 99 ? `99+` : user.notifications }}
                </sup>
              </div>
              <transition name="toBottom">
                <ul v-if="showProfileMenu">
                  <span :class="{ 'active': showProfileMenu}" @click="showProfileMenu = !showProfileMenu">
                    <!-- {{ user.email }} -->
                    <i class="icon-close" />
                  </span>
                  <nuxt-link to="/donate" tag="div" class="sitecoin balance">
                    <i />
                    <span>{{ user.balance.toFixed(2) }} Монет</span>
                    <span style="display:block;top:-44px;left:-27px;background-color: transparent;font-size:1.2rem;color:blue;">Пополнить</span>
                  </nuxt-link>
                <nuxt-link to="/donate" tag="li" v-if="user.group === 777" class="user-premium">
                  <strong v-if="user.premium_date">Premium до {{ user.premium_date | filterDate }}</strong>
                  <strong v-else>Premium до Бесконечности</strong>
                </nuxt-link>
                <li class="profile-avatar">
                  <div style="position: relative;">
                    <aura-component :user="user" />
                    <artifact-component :user="user" />
                    <lvl-component v-if="user.exp" :exp="user.exp" />
                    <div :class="`${user.aura_color ? `aura-color-${user.aura_color}` : ''} ${user.gender ? `gender-${user.gender}` : ''}`" class="avatar">
                      <img
                        :src="user.photo || '/no-avatar.svg'"
                        alt=""
                      >
                    </div>
                  </div>
                  <!-- <span @click="avatarUploadTrigger">Изменить</span>
                  <input
                    ref="avatarUpload"
                    type="file"
                    style="display:none;"
                    accept="image/gif, image/png, image/jpeg, image/jpg"
                    @change="avatarUpload"
                  > -->
                </li>
                <li class="user-name">
                  <strong>{{ user.name ? user.name : 'No name' }}</strong>
                </li>
                  <nuxt-link v-if="!showSettging" to="/profile" tag="li" class="open-profile">
                    Перейти в профиль
                  </nuxt-link>
                  <li class="col-2">
                    <span>
                      <nuxt-link to="/notifications">&#128276; Уведомления <sup v-if="user.notifications">{{ user.notifications > 99 ? `99+` : user.notifications }}</sup></nuxt-link>
                    </span>
                    <span><nuxt-link to="/cinema/?my=true">&#128250; Мои комнаты</nuxt-link></span>
                  </li>
                  <li class="col-2">
                    <span><nuxt-link to="/profile?tab=favs">&#129505; Закладки</nuxt-link></span>
                    <span><nuxt-link to="/profile?tab=pms">&#128233; Письма</nuxt-link></span>
                  </li>
                  <li class="col-2">
                    <span>
                      <nuxt-link to="/store">&#128302; Магазин</nuxt-link>
                    </span>
                    <span @click="$store.commit('toggleFeedback', true)">🎧 Поддержка</span>
                  </li>
                  <li class="col-2">
                    <span>
                      <nuxt-link to="/partners">&#127774; Партнерка</nuxt-link>
                    </span>
                    <span>
                      <nuxt-link to="/promocodes">&#11088; Промокоды</nuxt-link>
                    </span>
                  </li>
                  <li v-if="!showSettging" @click="logout" style="cursor:pointer;">
                    <span>&#9940; Выйти из аккаунта</span>
                  </li>
                </ul>
              </transition>
            </div>
            <div v-else class="profile">
              <i class="icon-short-search" @click="showSearch" />
              <i class="icon-short-profile" @click="showAuthForm = !showAuthForm" />
              <transition name="toBottom">
                <div v-if="showAuthForm" class="auth-form">
                  <button :class="{ active: showAuthForm }" @click="showAuthForm = !showAuthForm">
                    Вход на сайт
                    <i class="icon-close" />
                  </button>
                  <div class="auth-social">
                    <span @click="authSocial('facebook')" class="facebook"><i /> Facebook</span>
                    <span @click="authSocial('google')" class="google"><i /> Google</span>
                    <span @click="authSocial('vkontakte')" class="vkontakte"><i /> ВКонтакте</span>
                    <span @click="authSocial('odnoklassniki')" class="odnoklassniki"><i /> Одноклассники</span>
                    <span @click="authSocial('mailru')" class="mailru"><i /> Mail.ru</span>
                    <span @click="authSocial('yandex')" class="yandex"><i /> Яндекс</span>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <transition name="toBottom">
          <div v-if="openSearch" class="mobile-ssearch">
            <select
              v-model="searchType"
              :class="{ focused }"
              class="ssearch-type"
            >
              <option value="db">
                Аниме
              </option>
              <option value="anime_characters">
                Персонаж
              </option>
              <option value="comics">
                Комикс
              </option>
              <option value="ranobes">
                Ранобэ
              </option>
              <option value="games">
                Игра
              </option>
            </select>
            <searchhints-component :type="searchType" />
          </div>
        </transition>
        <transition name="fade">
          <div v-if="toggleMenu" @click="toggleMenu = !toggleMenu" class="menu-mobile-bg" />
        </transition>
        <transition name="toRight">
          <div v-if="toggleMenu" class="menu-mobile-open">
            <div style="margin-top:10px;margin-bottom:20px;margin-left:10px;">
              <a href="https://anime777app.ru" target="_blank">
                <img src="/static_files/icons/google.svg" alt="KIOFF на Google Play">
              </a>
            </div>
            <div @click="showProfileMenu = !showProfileMenu" class="open-profile">
              {{ user ? 'Открыть профиль' : 'Войти на сайт' }}
            </div>
            <ul onselectstart="return false" onmousedown="return false" class="main-menu">
              <h4 style="margin-bottom:30px;">
                <nuxt-link to="/popular">Топ100 - ваш выбор</nuxt-link>
              </h4>
              <li>
                <nuxt-link to="/users">
                  Поиск друзей
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/cinema/">
                  Совместный просмотр <sup class="flame"><img src="/static_files/icons/hot.svg" alt=""></sup>
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/tv">
                  Стрим трансляции
                </nuxt-link>
              </li>
              <li>
                <span @click="showMenus('anime')">
                  Аниме <i class="arrow-down" :class="{ active: showMenu.anime }" />
                </span>
                <div v-show="showMenu.anime">
                  <div class="slider">
                    <ul :style="'width:' + (enterData.top_anime.slice(0, 10).length * 190) + 'px'">
                      <li v-for="(film, i) in enterData.top_anime.slice(0, 10)" :key="`mobile-menu-top-anime-${i}`">
                        <img :src="film.poster" :alt="'Постер к ' + film.title_ru">
                        <strong>{{ film.title_ru }}</strong>
                        <nuxt-link :to="'/watch/' + postUrl(film.id, film.title_ru)" />
                      </li>
                    </ul>
                  </div>

                  <ul>
                    <li>
                      <nuxt-link to="/anime-new">
                        Новинки аниме {{ new Date().getFullYear() }} - {{ new Date().getFullYear() - 1 }}
                        <sup class="flame"><img src="/static_files/icons/hot.svg" alt=""></sup>
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link to="/watch" style="color:lightgreen;font-weight: bold;">
                        Каталог аниме <img src="/static_files/icons/right-chevron-dark.svg" style="width: 10px;height:10px;" alt="">
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link to="/random">
                        Случайное аниме
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link to="/schedule-anime">
                        Расписание выхода онгоингов
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link to="/characters">
                        Имена персонажей
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link to="/download-anime">
                        Скачать аниме
                      </nuxt-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <span @click="showMenus('comics')">
                  Комиксы <i class="arrow-down" :class="{ active: showMenu.comics }" />
                </span>

                <div v-show="showMenu.comics">
                  <div class="slider">
                    <ul :style="'width:' + (enterData.top_comics.slice(0, 10).length * 190) + 'px'">
                      <li v-for="(film, i) in enterData.top_comics.slice(0, 10)" :key="`mobile-menu-top-comics-${i}`">
                        <img :src="film.poster" :alt="'Постер к ' + film.title_ru">
                        <strong>
                          {{
                            film.title_ru.indexOf('/') >= 0
                              ? film.title_ru.slice(film.title_ru.indexOf('/') + 1)
                              : film.title_ru
                          }}
                        </strong>
                        <nuxt-link :to="`/comics/${postUrl(film.id, film.title_ru)}`" />
                      </li>
                    </ul>
                  </div>
                  <ul>
                    <li>
                      <nuxt-link to="/comics" style="color:lightgreen;font-weight: bold;">
                        Каталог манги <img src="/static_files/icons/right-chevron-dark.svg" style="width: 10px;height:10px;" alt="">
                      </nuxt-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <span @click="showMenus('ranobes')">
                  Ранобэ <i class="arrow-down" :class="{ active: showMenu.ranobes }" />
                </span>

                <div v-show="showMenu.ranobes">
                  <div class="slider">
                    <ul :style="'width:' + (enterData.top_ranobes.slice(0, 10).length * 190) + 'px'">
                      <li v-for="(film, i) in enterData.top_ranobes.slice(0, 10)" :key="`mobile-menu-top-comics-${i}`">
                        <img :src="film.poster" :alt="'Постер к ' + film.title_ru">
                        <strong>{{ film.title_ru }}</strong>
                        <nuxt-link :to="`/ranobes/${postUrl(film.id, film.title_ru)}`" />
                      </li>
                    </ul>
                  </div>
                  <ul>
                    <li>
                      <nuxt-link to="/ranobes" style="color:lightgreen;font-weight: bold;">
                        Каталог ранобэ <img src="/static_files/icons/right-chevron-dark.svg" style="width: 10px;height:10px;" alt="">
                      </nuxt-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <nuxt-link to="/updates/">
                  Обновления каталогов
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/amv">
                  AMV клипы
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/apps">
                  Мини-игры
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/chat">
                  Чат-рулетка
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/blogs">
                  Блоги
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/avatars">
                  Аватарки
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/gifs">
                  Гифки
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/wallpapers">
                  Обои
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/memes">
                  Мемы
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/games/">
                  Игры
                </nuxt-link>
              </li>
              <!-- <li>
                <i @click="switchLocale" class="icon-locale" />
              </li> -->
              <li>
                <h4 style="margin:30px 0 10px;">Наши проекты</h4>
                <ul>
                  <li style="color:#fff;">
                    <a href="https://taxivkino.ru" style="opacity:1;color:#fff;" target="_blank">
                      TaxiVkino (Такси в Кино) &rarr;
                    </a>
                    <span style="opacity:0.75;">- фильмы и сериалы онлайн</span>
                  </li>
                  <li style="color:#fff;">
                    <a href="https://moredoram.ru" style="opacity:1;color:#fff;" target="_blank">
                      MoreDoram (Море Дорам) &rarr;
                    </a>
                    <span style="opacity:0.75;">- дорамы онлайн</span>
                  </li>
                </ul>
              </li>
            </ul>

            <ul class="social">
              <li>
                <a href="https://twitter.com/anime_7_7_7" target="_blank" class="twitter" />
              </li>
              <li>
                <a href="https://vk.com/anime_7_7_7" target="_blank" class="vk" />
              </li>
              <li>
                <a href="https://www.pinterest.ru/anime_7_7_7" target="_blank" class="pinterest" />
              </li>
              <li>
                <a href="https://www.facebook.com/groups/anime777ru" target="_blank" class="facebook" />
              </li>
            </ul>
          </div>
        </transition>
      </nav>

      <transition name="fade">
        <div v-if="showFirstReg" @click="hideFirstReg" class="first-popup reg">
          <div onclick="event.stopPropagation()" class="modal">
            <div class="header">
              <h4>Присоединяйся к нам!</h4>
              <i class="icon-close" @click="hideFirstReg" />
            </div>
            <div class="body">
              <p>
                Зарегистрируй аккаунт и получи преимущество!
              </p>
              <ul>
                <li>добавляй аниме, ранобэ и комиксы манга в закладки</li>
                <li>создавай комнаты для совместного просмотра</li>
                <li>оставляй отзывы</li>
                <li>пиши рецензии</li>
                <li>общайся с другими участниками</li>
                <li>находи новых друзей</li>
                <li>получай уведомления о выходе онгоингов</li>
                <li>и многое другое...</li>
              </ul>
              <div class="auth-social">
                <span @click="authSocial('google')" class="google"><i /> Google</span>
                <span @click="authSocial('facebook')" class="facebook"><i /> Facebook</span>
                <span @click="authSocial('odnoklassniki')" class="odnoklassniki"><i /> Одноклассники</span>
                <span @click="authSocial('vkontakte')" class="vkontakte"><i /> ВКонтакте</span>
                <span @click="authSocial('yandex')" class="yandex"><i /> Яндекс</span>
                <span @click="authSocial('mailru')" class="mailru"><i /> Mail.ru</span>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="showTeamModal" @click="closeTeamModal" class="first-popup">
          <div onclick="event.stopPropagation()" class="modal">
            <div class="header">
              <span>Набор в команду проекта</span>
              <i class="icon-close" @click="closeTeamModal" />
            </div>
            <div class="body">
              <figure style="text-align:center;">
                <img src="/static_files/scrs/Vbs_Group.png" alt="Набор в команду" style="width:500px;">
                <figcaption style="font-size:1.4rem;opacity:0.75;">Присоединяйся к нам!</figcaption>
              </figure>
              <p>
                Ты талантлив и амбициозен? Тебе есть что предложить? Есть идеи, но тебя не слышат?!
                <strong>Присоединяйся к нам, мы открыты к новому!</strong>
              </p>
              <div style="text-align:center;">
                <nuxt-link to="/team" style="font-weight:bold;border-bottom:2px solid red;font-size:1.8rem;">
                  Узнать подробности
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </header>
  </transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    // snowComponent:() => import('~/components/snowComponent.vue'),
    lvlComponent: () => import('~/components/lvlComponent.vue'),
    auraComponent: () => import('~/components/auraComponent.vue'),
    artifactComponent: () => import('~/components/artifactComponent.vue'),
    searchhintsComponent: () => import('~/components/searchhintsComponent.vue')
  },
  filters: {
    filterDate(val) {
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const day = new Date(val).getDate()
      const month = new Date(val).getMonth()
      const year = new Date(val).getFullYear()
      const hour =
        new Date(val).getHours() >= 10
          ? new Date(val).getHours()
          : '0' + new Date(val).getHours()
      const min =
        new Date(val).getMinutes() >= 10
          ? new Date(val).getMinutes()
          : '0' + new Date(val).getMinutes()
      return `${day} ${months[month]} ${year} в ${hour}:${min}`
    }
  },
  watch: {
    searchType() {
      document.getElementById('ssearch').value = ''
    },
    openSearch() {
      this.toggleMenu = false
    },
    setShowAuthForm() {
      if (this.setShowAuthForm) this.showAuthForm = this.setShowAuthForm
    },
    showProfileMenu(val) {
      if (val) this.toggleMenu = false

    },
    toggleMenu(val) {
      if (val) this.showProfileMenu = false
    },
    changeRoute() {
      this.activeLink()

      this.toggleMenu = false
      this.showMenu = {
        anime: false,
        comics: false,
        ranobes: false
      }

      this.searchQuery = ''
      this.openSearch = false

      this.showProfileMenu = false
      this.showAuthForm = false

      this.showTeamModal = false

      function rand(min = 0, max = 255) {
        return `${
            Math.floor(Math.random() * (max - min + 1)) + min
          }, ${
            Math.floor(Math.random() * (max - min + 1)) + min
          }, ${
            Math.floor(Math.random() * (max - min + 1)) + min
          }`
      }
      const logo = document.querySelector('.header-menu .logotype span:first-child')
      logo.style.color = `rgba(${rand(100, 255)}, 1)`
    }
  },
  computed: {
    ...mapState(['error', 'user', 'isDesktop', 'setShowAuthForm', 'enterData']),
    changeRoute() {
      return this.$route.fullPath
    }
  },
  data() {
    const years = []
    for(let i = new Date().getFullYear(); i >= 1958; i--) {
      years.push(i)
    }
    return {
      showSnow: process.env.NODE_ENV === 'production',
      usProjects: false,
      showCatsSpoiler: false,
      searchType: 'db',
      focused: false,
      years: years,
      animeGenres: ['Безумие','Боевые искусства','Вампиры','Военное','Гарем','Демоны','Детектив','Детское','Дзёсей','Драма','Игры','Исторический','Комедия','Космос','Магия','Машины','Меха','Музыка','Пародия','Повседневность','Полиция','Приключения','Психологическое','Романтика','Самураи','Сверхъестественное','Сейнен','Спорт','Супер сила','Сёдзё','Сёдзё-ай','Сёнен','Сёнен-ай','Триллер','Ужасы','Фантастика','Фэнтези','Хентай','Школа','Экшен','Этти','Яой'],
      comicsGenres: [
        'боевые искусства', 'боевик', 'вампиры', 'гарем', 'гендерная интрига', 'героическое фэнтези', 'додзинси',
        'дзёсэй', 'драма', 'детектив', 'игра', 'история', 'киберпанк', 'комедия', 'мистика',
        'меха', 'махо-сёдзё', 'научная фантастика', 'перерождение', 'повседневность', 'приключения',
        'психология', 'романтика', 'самурайский боевик', 'сборник', 'сверхъестественное', 'сёдзё',
        'сёдзё для взрослых', 'сёдзё-ай', 'сёнэн', 'сёнэн-ай', 'спокон', 'сэйнэн', 'спорт', 'трагедия',
        'триллер', 'ужасы', 'фантастика', 'фэнтези', 'школа', 'эротика', 'этти', 'юри', 'яой'
      ],
      ranobeGenres: [
        'Боевые искусства', 'Гарем', 'Гендер бендер', 'Дзёсэй', 'Для взрослых', 'Драма', 'Исторический', 'Комедия', 'Лоликон',
        'Меха', 'Милитари', 'Мистика', 'Научная фантастика', 'Непристойность', 'Повседневность', 'Приключение',
        'Психология', 'Романтика', 'Сверхъестественное', 'Спорт', 'Сэйнэн', 'Сюаньхуа', 'Сянься', 'Сёдзё', 'Сёдзё-ай',
        'Сёнэн', 'Сёнэн-ай', 'Трагедия', 'Триллер', 'Ужасы', 'Уся', 'Фэнтези', 'Школьная жизнь', 'Экшн', 'Эччи', 'Юри', 'Яой'
      ],
      animeCountries: ['Канада', 'Китай', 'Корея Южная', 'Тайвань', 'Филиппины', 'Япония'],
      ranobeCountries: ['Китай', 'США', 'Япония'],
      loading: false,
      toggleMenu: false,
      showProfileMenu: false,
      showAuthForm: false,
      showRegForm: false,
      showResetForm: false,
      showSettging: false,
      showFirstReg: false,
      name: '',
      email: '',
      password: '',
      photo: '',
      showEditAvatar: false,
      showEditName: false,
      menuTimers: {},
      showMenu: {
        anime: false,
        comics: false,
        ranobes: false
      },
      openSearch: false,
      showMenuBar: true,
      notifications: 0,
      gets: {},
      showVseMaykiBanner: false,
      showSearchInput: false,
      showTeamModal: false
    }
  },
  mounted() {
    this.activeLink()

    // const date = `${new Date().getFullYear()}_${new Date().getMonth()}_${new Date().getDate()}`

    // if (!localStorage.getItem(`banner_vsemayki_${date}`)) {
    //   setTimeout(() => {
    //     this.showVseMaykiBanner = true
    //   }, 60 * 1000)
    // }

    // if (this.user) {
    //   if (!localStorage.getItem('us-projects')) {
    //     setTimeout(() => {
    //       this.usProjects = true
    //     }, 180 * 1000)
    //   }

    //   if (!localStorage.getItem('team')) {
    //     setTimeout(() => {
    //       this.showTeamModal = true
    //     }, 300 * 1000)
    //   }
    // } else if (!localStorage.getItem('first_reg')) {
    //   setTimeout(() => {
    //     this.showFirstReg = true
    //   }, 120 * 1000)
    // }

    // let lastPosition = 0

    // window.addEventListener('scroll', () => {
    //   // const iframeEnter = document.querySelector('.event-iframe iframe.enter')
    //   // if (iframeEnter) {
    //   //   this.showMenuBar = false
    //   //   return
    //   // }
    //   const currentPosition = document.documentElement.scrollTop
    //   if (currentPosition === 0) {
    //     if (!this.toggleMenu) this.showMenuBar = true
    //     lastPosition = 0
    //   } else if (lastPosition > currentPosition) {
    //     this.showMenuBar = true
    //   } else if (!this.toggleMenu) {
    //     this.showMenuBar = false
    //   }
    //   if (lastPosition < currentPosition - 50) lastPosition = currentPosition
    // })
  },
  methods: {
    closeTeamModal() {
      this.showTeamModal = false
      localStorage.setItem('team', 'true')
    },
    closeUsProjects() {
      this.usProjects = false
      localStorage.setItem('us-projects', 'true')
    },
    closeBannerVseMayki() {
      this.showVseMaykiBanner = false
      const date = `${new Date().getFullYear()}_${new Date().getMonth()}_${new Date().getDate()}`
      localStorage.setItem(`banner_vsemayki_${date}`, 'true')
    },
    activeLink() {
      const linkList = document.querySelectorAll('[data-route]')
      if (linkList) {
        linkList.forEach(el => {
          const elem = el
          const routeName = this.$route.name
          if (
            routeName && this.$route.name.includes(el.dataset.route)
          ) {
            elem.style.color = 'rgb(247, 66, 36)' // "#b8a177"
          } else {
            elem.style.color = ''
          }
        })
      }
    },
    switchLocale() {
      if (!localStorage.getItem('localized')) {
        localStorage.setItem('localized', 'true')
        document.body.setAttribute('data-localized', 'en')
      } else {
        localStorage.removeItem('localized')
        document.body.removeAttribute('data-localized')
      }
    },
    switchTheme() {
      if (localStorage.getItem('theme')) {
        if (localStorage.getItem('theme') === 'dark') {
          document.body.className = ''
          localStorage.setItem('theme', 'light')
          this.$store.commit('setDarkTheme', false)
        } else {
          document.body.className = 'dark'
          localStorage.setItem('theme', 'dark')
          this.$store.commit('setDarkTheme', true)
        }
      } else {
        document.body.className = 'dark'
        localStorage.setItem('theme', 'dark')
        this.$store.commit('setDarkTheme', true)
      }
      // if (!(this.user && this.user.profile_bg)) {
      //   document.body.style.background = `url("/bg/bg_winter_${document.body.className}.webp") center top / cover no-repeat fixed`
      // }
    },
    hideFirstReg() {
      this.showFirstReg = false
      localStorage.setItem('first_reg', 'true')
    },
    showMenus(type) {
      if (this.isDesktop) {
        this.menuTimers[type] = setTimeout(() => {
          this.showMenu = {
            anime: false,
            comics: false,
            ranobes: false
          }
          this.showMenu[type] = true
        }, 200)
      } else {
        this.showMenu[type] = !this.showMenu[type]
        // this.showMenu = {
        //   anime: false,
        //   comics: false,
        //   ranobes: false
        // }
      }
    },
    hideMenus(type) {
      clearTimeout(this.menuTimers[type])
    },
    // hideMenu(type) {
    //   this.showMenu[type] = false
    // },
    showSearch() {
      this.openSearch = !this.openSearch
      this.searchHints = []
      this.searchQuery = ''
    },
    async logout() {
      await this.$axios.get('/api/auth/logout')
      // console.log('logout', data)
      // this.$store.commit('setUser', null)
      // this.name = ''
      // this.email = ''
      // this.password = ''
      // this.showProfileMenu = false
      // this.showAuthForm = false
      // this.showRegForm = false
      this.$store.commit('setUser', null)
      location.reload()
    },
    authSocial(social) {
      const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      width=900,height=600,left=10,top=10`
      window.open(`/api/auth/${social}`, `Auth ${social}`, params)
    }
  }
}
</script>

<style src="~/assets/menu.styl" lang="stylus" />
